import { useRouter } from 'next/router'
import { type BaseSyntheticEvent, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import cx from 'classnames'

import { type SanityLoginFormStrings } from '@data/sanity/queries/types/blocks'
import { type LoginFormValues, useLoginUser } from '@lib/auth'
import { type ErrorMessages } from '@lib/helpers'
import { LanguageContext } from '@lib/language-context'
import { getLinkPageUrl, getPageUrl } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import Button, { ButtonVariant } from '@components/buttons/button'
import InputField from '@components/input-field'
import ComplexPortableText from '@components/complex-portable-text'
import ButtonLink from '@components/buttons/button-link'
import { type ParseStatus } from '@lib/request'

interface LoginFormProps {
  className?: string
  loginFormStrings: SanityLoginFormStrings
}

const LoginForm = ({ loginFormStrings, className }: LoginFormProps) => {
  const { locale } = useContext(LanguageContext)

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<LoginFormValues>()
  const [isError, setIsError] = useState(false)
  const [errorStatus, setErrorStatus] = useState<ParseStatus>()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})

  const strings = useContext(StringsContext)
  const router = useRouter()
  const loginUser = useLoginUser()

  // Handle form submission
  const onSubmit = async (
    values: LoginFormValues,
    event?: BaseSyntheticEvent,
  ) => {
    event?.preventDefault()

    setIsLoading(true)
    setIsError(false)

    // Login
    const loginUserResult = await loginUser(values)
    setErrorMessages(loginUserResult.fieldErrors)

    if (loginUserResult.status !== 'ok') {
      setErrorStatus(loginUserResult.status)
      setIsError(true)
    }

    if (loginUserResult.status === 'ok' && loginUserResult.errorCount === 0) {
      reset()

      // Redirect to account after logging in
      const url = getLinkPageUrl('accountPage')
      router.push(url, url, {
        locale,
      })
    }

    setIsLoading(false)
  }

  const emailRegister = register('email', {
    required: loginFormStrings.signupEmailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: strings.emailInvalid,
    },
  })

  const passwordRegister = register('password', {
    required: loginFormStrings.signupPasswordMissing,
  })

  const isDisabled = !!errors.email || !!errors.password

  const passwordRecoveryPageUrl = getPageUrl(locale, 'passwordRecoveryPage')
  const signupPageUrl = getPageUrl(locale, 'signupPage')

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cx('flex flex-col gap-y-12', className)}
    >
      <h1 className="is-large">{loginFormStrings.loginTitle}</h1>

      <div className="flex flex-col gap-y-6">
        <InputField
          id="login-email"
          type="email"
          autoComplete="email"
          formRegister={emailRegister}
          errorMessage={errorMessages?.email ?? errors.email?.message}
          label={loginFormStrings.signupEmail}
          placeholder={loginFormStrings.signupEmailPlaceholder}
        />

        <InputField
          id="login-password"
          type="password"
          autoComplete="off"
          formRegister={passwordRegister}
          errorMessage={errorMessages?.password ?? errors.password?.message}
          label={loginFormStrings.signupPassword}
          placeholder={loginFormStrings.signupPasswordPlaceholder}
        />
      </div>

      <div className="flex flex-col gap-y-6">
        {isError && errorStatus && (
          <div key="error">
            <Alert error className="rc rc-alert rc-error">
              {errorStatus === 'invalid_credentials' && (
                <ComplexPortableText
                  content={loginFormStrings.loginFailedMessage}
                />
              )}
              {errorStatus !== 'invalid_credentials' && (
                <ComplexPortableText
                  content={loginFormStrings.loginErrorMessage}
                />
              )}
            </Alert>
          </div>
        )}

        <Button
          type="submit"
          variant={ButtonVariant.FILLED}
          disabled={isLoading || isDisabled}
          className="w-full"
        >
          {isLoading ? strings.buttonSubmitting : loginFormStrings.loginSubmit}
        </Button>

        <div className="flex flex-wrap items-center justify-center gap-x-6 gap-y-3 uppercase">
          <ButtonLink href={passwordRecoveryPageUrl}>
            {loginFormStrings.loginPasswordRecoveryLinkText}
          </ButtonLink>

          <ButtonLink href={signupPageUrl}>
            {loginFormStrings.loginSignupLinkText}
          </ButtonLink>
        </div>
      </div>
    </form>
  )
}

export default LoginForm
