import { type BaseSyntheticEvent, useContext, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import cx from 'classnames'

import { type SanityAddressFormStrings } from '@data/sanity/queries/types/blocks'
import {
  useCreateAddress,
  useUpdateAddress,
} from '@lib/shopify/graphql/customer-address'
import { useUser } from '@lib/auth'
import { CountryCode, countryNames } from '@lib/country'
import { type ErrorMessages } from '@lib/helpers'
import { LanguageContext } from '@lib/language-context'
import { StringsContext } from '@lib/strings-context'
import { type AddressFormValues } from '@lib/user'

import Alert from '@components/alert'
import Button, { ButtonVariant } from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import InputDropdown, { type DropdownOption } from '@components/input-dropdown'
import InputField from '@components/input-field'
import ComplexPortableText from '@components/complex-portable-text'

export enum AddressFormMode {
  CREATE = 'create',
  EDIT = 'edit',
}

interface AddressFormProps {
  mode: AddressFormMode
  hide: () => void
  addressId?: string
  defaultValues?: AddressFormValues
  addressFormStrings: SanityAddressFormStrings
  className?: string
}

const AddressForm = ({
  addressFormStrings,
  mode,
  hide,
  addressId,
  defaultValues,
  className,
}: AddressFormProps) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<AddressFormValues>()
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { locale } = useContext(LanguageContext)
  const strings = useContext(StringsContext)
  const createAddress = useCreateAddress()
  const updateAddress = useUpdateAddress()
  const { user } = useUser()

  // Country list
  const countryOptions = useMemo<DropdownOption[]>(
    () =>
      Object.entries(countryNames[locale]).map(([code, title]) => ({
        value: countryNames.en[code as CountryCode],
        title,
      })),

    [locale],
  )

  // Handle form submission
  const onSubmit = async (
    values: AddressFormValues,
    event?: BaseSyntheticEvent,
  ) => {
    event?.preventDefault()

    const token = user?.token

    if (!token) {
      setIsError(true)
      return
    }

    setIsLoading(true)
    setIsError(false)

    if (mode === AddressFormMode.CREATE) {
      // Create address
      const createAddressResult = await createAddress(values, token)
      setErrorMessages(createAddressResult.fieldErrors)

      if (createAddressResult.status !== 'ok') {
        setIsError(true)
      }

      if (
        createAddressResult.status === 'ok' &&
        createAddressResult.errorCount === 0
      ) {
        reset()
        hide()
      }
    }

    if (mode === AddressFormMode.EDIT && addressId) {
      // Update address
      const updateAddressResult = await updateAddress(addressId, values, token)
      setErrorMessages(updateAddressResult.fieldErrors)

      if (updateAddressResult.status !== 'ok') {
        setIsError(true)
      }

      if (
        updateAddressResult.status === 'ok' &&
        updateAddressResult.errorCount === 0
      ) {
        reset()
        hide()
      }
    }

    setIsLoading(false)
  }

  const firstNameRegister = register('firstName', {
    required: addressFormStrings.accountFirstNameMissing,
  })
  const lastNameRegister = register('lastName', {
    required: addressFormStrings.accountLastNameMissing,
  })
  const companyRegister = register('company', {
    // required: addressFormStrings.accountCompanyMissing,
  })
  const address1Register = register('address1', {
    required: addressFormStrings.accountAddressLine1Missing,
  })
  const address2Register = register('address2')
  const cityRegister = register('city', {
    required: addressFormStrings.accountCityMissing,
  })
  const countryRegister = register('country', {
    required: addressFormStrings.accountCountryMissing,
  })
  const zipRegister = register('zip', {
    required: addressFormStrings.accountZipMissing,
  })
  const phoneRegister = register('phone', {
    required: addressFormStrings.accountPhoneMissing,
  })
  const isDefaultRegister = register('isDefault')

  const isDisabled =
    !!errors.firstName ||
    !!errors.lastName ||
    !!errors.company ||
    !!errors.address1 ||
    !!errors.city ||
    !!errors.country ||
    !!errors.zip ||
    !!errors.phone

  return (
    <div className={cx('space-y-12', className)}>
      <h2>
        {mode === 'create'
          ? addressFormStrings.accountAddAddressHeading
          : addressFormStrings.accountEditAddressHeading}
      </h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-6 gap-x-5 gap-y-6">
          <div className="col-span-6 sm:col-span-3">
            <InputField
              id="address-fist-name"
              type="text"
              formRegister={firstNameRegister}
              defaultValue={defaultValues?.firstName}
              errorMessage={
                errorMessages?.firstName ?? errors.firstName?.message
              }
              label={addressFormStrings.accountFirstName}
              placeholder={addressFormStrings.accountFirstNamePlaceholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              id="address-last-name"
              type="text"
              formRegister={lastNameRegister}
              defaultValue={defaultValues?.lastName}
              errorMessage={errorMessages?.lastName ?? errors.lastName?.message}
              label={addressFormStrings.accountLastName}
              placeholder={addressFormStrings.accountLastNamePlaceholder}
            />
          </div>

          <div className="col-span-6">
            <InputField
              id="address-company"
              type="text"
              formRegister={companyRegister}
              defaultValue={defaultValues?.company}
              errorMessage={errorMessages?.company ?? errors.company?.message}
              label={addressFormStrings.accountCompany}
              placeholder={addressFormStrings.accountCompanyPlaceholder}
            />
          </div>

          <div className="col-span-6">
            <InputField
              id="address-address-line-1"
              type="text"
              formRegister={address1Register}
              defaultValue={defaultValues?.address1}
              errorMessage={errorMessages?.address1 ?? errors.address1?.message}
              label={addressFormStrings.accountAddressLine1}
              placeholder={addressFormStrings.accountAddressLine1Placeholder}
            />
          </div>

          <div className="col-span-6">
            <InputField
              id="address-address-line-2"
              type="text"
              formRegister={address2Register}
              defaultValue={defaultValues?.address2}
              errorMessage={errorMessages?.address2 ?? errors.address2?.message}
              label={addressFormStrings.accountAddressLine2}
              placeholder={addressFormStrings.accountAddressLine2Placeholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              id="address-city"
              type="text"
              formRegister={cityRegister}
              defaultValue={defaultValues?.city}
              errorMessage={errorMessages?.city ?? errors.city?.message}
              label={addressFormStrings.accountCity}
              placeholder={addressFormStrings.accountCityPlaceholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputDropdown
              id="address-country"
              formRegister={countryRegister}
              defaultValue={defaultValues?.country}
              options={countryOptions}
              errorMessage={errorMessages?.country ?? errors.country?.message}
              label={addressFormStrings.accountCountry}
              placeholder={addressFormStrings.accountCountryPlaceholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              id="address-zip"
              type="text"
              formRegister={zipRegister}
              defaultValue={defaultValues?.zip}
              errorMessage={errorMessages?.zip ?? errors.zip?.message}
              label={addressFormStrings.accountZip}
              placeholder={addressFormStrings.accountZipPlaceholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              id="address-phone"
              type="text"
              formRegister={phoneRegister}
              defaultValue={defaultValues?.phone}
              errorMessage={errorMessages?.phone ?? errors.phone?.message}
              label={addressFormStrings.accountPhone}
              placeholder={addressFormStrings.accountPhonePlaceholder}
            />
          </div>

          <div className="col-span-6">
            <Checkbox
              id="address-is-default"
              formRegister={isDefaultRegister}
              defaultChecked={defaultValues?.isDefault}
              className="text-sm"
            >
              {addressFormStrings.accountSetAsDefault}
            </Checkbox>
          </div>

          {isError && (
            <Alert error className="col-span-6 rc rc-alert rc-error">
              <ComplexPortableText
                content={addressFormStrings.addressErrorMessage}
              />
            </Alert>
          )}

          <Button
            type="submit"
            variant={ButtonVariant.FILLED}
            disabled={isLoading || isDisabled}
            className="col-span-6 sm:col-span-3"
          >
            {isLoading
              ? strings.buttonSubmitting
              : mode === 'create'
                ? addressFormStrings.accountAddAddress
                : addressFormStrings.accountEditAddress}
          </Button>

          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={(event) => {
              event.preventDefault()
              hide()
            }}
            className="col-span-6 sm:col-span-3"
          >
            {strings.buttonCancel}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default AddressForm
