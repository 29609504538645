import { type FetchResult } from '@apollo/client'

import {
  type CustomerAccessTokenCreateMutation,
  type CustomerAccessTokenCreateMutationVariables,
  type CustomerAccessTokenDeleteMutation,
  type CustomerAccessTokenDeleteMutationVariables,
  type CustomerCreateInput,
  type CustomerCreateMutation,
  type CustomerCreateMutationVariables,
  type CustomerRecoverMutation,
  type CustomerRecoverMutationVariables,
  type GetCustomerOrdersQuery,
  type GetCustomerOrdersQueryVariables,
  CustomerErrorCode,
} from '@data/shopify/storefront/types'
import {
  CUSTOMER_ACCESS_TOKEN_CREATE,
  CUSTOMER_ACCESS_TOKEN_DELETE,
  CUSTOMER_CREATE,
  CUSTOMER_RECOVER,
} from '@data/shopify/storefront/mutations/customer'
import { GET_CUSTOMER_ORDERS } from '@data/shopify/storefront/queries/customer'
import { type StoredUser } from '@lib/auth'
import { type Locale } from '@lib/language'
import { type ParseResults } from '@lib/request'
import {
  type ShopifyClient,
  mutateShopifyStorefront,
  queryShopifyStorefront,
} from './client'
import { getGrqphQLErrorFieldName, parseMutationResult } from './helpers'

/**
 * Gets customer creation validation results.
 */
const parseCustomerCreateResult = (
  customerCreateResult: FetchResult<CustomerCreateMutation>,
): ParseResults => {
  return parseMutationResult(
    customerCreateResult.data?.customerCreate?.customerUserErrors ?? [],
  )
}

/**
 * Gets customer login validation results.
 */
const parseCustomerAccessTokenCreateResult = (
  customerAccessTokenCreateResult: FetchResult<CustomerAccessTokenCreateMutation>,
): ParseResults => {
  const customerUserErrors =
    customerAccessTokenCreateResult.data?.customerAccessTokenCreate
      ?.customerUserErrors ?? []
  const results: ParseResults = {
    fieldErrors: {},
    errorCount: customerUserErrors.length,
    status: 'ok',
  }

  customerUserErrors.forEach((customerUserError) => {
    const fieldName = getGrqphQLErrorFieldName(customerUserError.field)

    if (fieldName) {
      results.fieldErrors[fieldName] = customerUserError.message
    }

    if (customerUserError.code === CustomerErrorCode.UnidentifiedCustomer) {
      results.status = 'invalid_credentials'
    }
  })

  return results
}

/**
 * Gets customer recovery validation results.
 */
const parseCustomerRecoverResult = (
  customerRecoverResult: FetchResult<CustomerRecoverMutation>,
): ParseResults => {
  return parseMutationResult(
    customerRecoverResult.data?.customerRecover?.customerUserErrors ?? [],
  )
}

/**
 * Creates a new Shopify user account.
 */
export const createUser = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  values: CustomerCreateInput,
) => {
  try {
    const customerCreateResult = await mutateShopifyStorefront<
      CustomerCreateMutation,
      CustomerCreateMutationVariables
    >(locale, shopifyStorefrontGraphQlClient, CUSTOMER_CREATE, {
      input: values,
    })

    return parseCustomerCreateResult(customerCreateResult)
  } catch (error) {
    console.log(error)

    return {
      fieldErrors: {},
      errorCount: 0,
      status: 'unknown_error',
    }
  }
}

interface UserAccessTokenResponse {
  loginUserResult: ParseResults
  user?: StoredUser
}

/**
 * Creates a new user access token from credentials.
 */
export const createUserAccessToken = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  email: string,
  password: string,
): Promise<UserAccessTokenResponse> => {
  // Create user token
  const customerAccessTokenCreateResult = await mutateShopifyStorefront<
    CustomerAccessTokenCreateMutation,
    CustomerAccessTokenCreateMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, CUSTOMER_ACCESS_TOKEN_CREATE, {
    input: {
      email,
      password,
    },
  })
  const loginUserResult = parseCustomerAccessTokenCreateResult(
    customerAccessTokenCreateResult,
  )

  if (loginUserResult.status !== 'ok' || loginUserResult.errorCount > 0) {
    return { loginUserResult }
  }

  const token =
    customerAccessTokenCreateResult.data?.customerAccessTokenCreate
      ?.customerAccessToken?.accessToken ?? ''
  const user: StoredUser = {
    isLoggedIn: true,
    email,
    token,
  }

  return {
    loginUserResult,
    user,
  }
}

/**
 * Deletes a user access token.
 */
export const deleteUserAccessToken = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  customerAccessToken: string,
) => {
  // Delete user token
  await mutateShopifyStorefront<
    CustomerAccessTokenDeleteMutation,
    CustomerAccessTokenDeleteMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, CUSTOMER_ACCESS_TOKEN_DELETE, {
    customerAccessToken,
  })
}

/**
 * Sends a password recovery email.
 */
export const recoverUserPassword = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  email: string,
) => {
  try {
    const customerRecoverResult = await mutateShopifyStorefront<
      CustomerRecoverMutation,
      CustomerRecoverMutationVariables
    >(locale, shopifyStorefrontGraphQlClient, CUSTOMER_RECOVER, {
      email,
    })

    return parseCustomerRecoverResult(customerRecoverResult)
  } catch (error) {
    console.log(error)

    return {
      fieldErrors: {},
      errorCount: 0,
      status: 'unknown_error',
    }
  }
}

/**
 * Gets customer orders from Shopify.
 */
export const getShopifyUserOrders = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  customerAccessToken: string,
  afterCursor: string | null,
  orderLimit = 10,
) => {
  try {
    const getCustomerOrdersResult = await queryShopifyStorefront<
      GetCustomerOrdersQuery,
      GetCustomerOrdersQueryVariables
    >(locale, shopifyStorefrontGraphQlClient, GET_CUSTOMER_ORDERS, {
      customerAccessToken,
      orderLimit,
      afterCursor,
    })

    return getCustomerOrdersResult.data
  } catch (error) {
    console.log(error)

    return { customer: null }
  }
}
