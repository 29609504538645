import { CustomerErrorCode } from '@data/shopify/storefront/types'
import { type ParseResults } from '@lib/request'

interface CustomerUserError {
  __typename?: 'CustomerUserError'
  code?: CustomerErrorCode | null
  field?: string[] | null
  message: string
}

/**
 * Gets field name from Shopify GraphQL API result error.
 */
export const getGrqphQLErrorFieldName = (errorField?: string[] | null) => {
  const count = errorField?.length ?? 0

  if (errorField && count > 0) {
    return errorField[count - 1]
  }
}

/**
 * Gets error field validation results.
 */
export const parseMutationResult = (
  customerUserErrors: CustomerUserError[],
): ParseResults => {
  const results: ParseResults = {
    fieldErrors: {},
    errorCount: customerUserErrors.length,
    status: 'ok',
  }

  customerUserErrors.forEach((customerUserError) => {
    const fieldName = getGrqphQLErrorFieldName(customerUserError.field)

    if (fieldName) {
      results.fieldErrors[fieldName] = customerUserError.message
    }
  })

  return results
}
