import { type BaseSyntheticEvent, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import cx from 'classnames'

import { recoverUserPassword } from '@lib/shopify/graphql/customer'
import { type SanityPasswordRecoveryFormStrings } from '@data/sanity/queries/types/blocks'
import { PasswordRecoveryFormValues } from '@lib/auth'
import { type ErrorMessages } from '@lib/helpers'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'
import { getPageUrl } from '@lib/routes'

import { LanguageContext } from '@lib/language-context'
import Alert from '@components/alert'
import Button, { ButtonVariant } from '@components/buttons/button'
import InputField from '@components/input-field'
import ComplexPortableText from '@components/complex-portable-text'
import ButtonLink from '@components/buttons/button-link'

interface PasswordRecoveryFormProps {
  className?: string
  passwordRecoveryFormStrings: SanityPasswordRecoveryFormStrings
}

const PasswordRecoveryForm = ({
  passwordRecoveryFormStrings,
  className,
}: PasswordRecoveryFormProps) => {
  const { locale } = useContext(LanguageContext)

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<PasswordRecoveryFormValues>()
  const strings = useContext(StringsContext)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})
  const [isLoading, setIsLoading] = useState(false)
  const { shopifyStorefrontGraphQlClient } = useContext(ShopContext)

  // Handle form submission
  const onSubmit = async (
    values: PasswordRecoveryFormValues,
    event?: BaseSyntheticEvent,
  ) => {
    event?.preventDefault()

    if (!shopifyStorefrontGraphQlClient) {
      throw new Error('Shopify Storefront API client missing')
    }

    setIsLoading(true)
    setIsError(false)

    const recoverUserPasswordResult = await recoverUserPassword(
      locale,
      shopifyStorefrontGraphQlClient,
      values.email,
    )
    setErrorMessages(recoverUserPasswordResult.fieldErrors)

    if (recoverUserPasswordResult.status !== 'ok') {
      setIsError(true)
    }

    if (
      recoverUserPasswordResult.status === 'ok' &&
      recoverUserPasswordResult.errorCount === 0
    ) {
      reset()
      setIsSuccess(true)
    }

    setIsLoading(false)
  }

  const emailRegister = register('email', {
    required: passwordRecoveryFormStrings.signupEmailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: strings.emailInvalid,
    },
  })

  const isDisabled = !!errors.email

  const loginPageUrl = getPageUrl(locale, 'loginPage')

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cx('flex flex-col gap-y-12', className)}
    >
      <h1 className="is-large">
        {passwordRecoveryFormStrings.passwordRecoveryTitle}
      </h1>

      {isSuccess && (
        <Alert success className="rc rc-alert rc-success">
          <ComplexPortableText
            content={passwordRecoveryFormStrings.passwordRecoverySuccessMessage}
          />
        </Alert>
      )}

      {!isSuccess && (
        <>
          <InputField
            id="signup-email"
            type="email"
            autoComplete="email"
            formRegister={emailRegister}
            errorMessage={errorMessages?.email ?? errors.email?.message}
            label={passwordRecoveryFormStrings.signupEmail}
            placeholder={passwordRecoveryFormStrings.signupEmailPlaceholder}
          />

          <div className="flex flex-col gap-y-6">
            {isError && (
              <div key="error">
                <Alert error className="rc rc-alert rc-error">
                  <ComplexPortableText
                    content={
                      passwordRecoveryFormStrings.passwordRecoveryErrorMessage
                    }
                  />
                </Alert>
              </div>
            )}

            <Button
              type="submit"
              variant={ButtonVariant.FILLED}
              disabled={isLoading || isDisabled}
              className="w-full"
            >
              {isLoading
                ? strings.buttonSubmitting
                : passwordRecoveryFormStrings.passwordRecoverySubmit}
            </Button>

            <ButtonLink href={loginPageUrl} className="self-center uppercase">
              {passwordRecoveryFormStrings.passwordRecoveryLoginLink}
            </ButtonLink>
          </div>
        </>
      )}
    </form>
  )
}

export default PasswordRecoveryForm
